import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Public',
      component: () => import('@/views/dashboard/Public'),
      children: [
        // Dashboard
        {
          name: 'Login',
          path: 'login',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        {
          name: 'Logout',
          path: 'logout',
          component: () => import('@/views/dashboard/views/Logout')
        },
      ],
    },
    {
      path: '/admin',
      name: 'Admin',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'AdminDashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        {
          name: 'AdminTrainings',
          path: 'trainings',
          component: () => import('@/views/dashboard/views/trainings/TrainingIndex')
        },
        {
          name: 'AdminVideos',
          path: 'videos',
          component: () => import('@/views/dashboard/views/videos/VideosIndex')
        }
      ],
    },
  ],
})
